<template>
  <div
    class="position-fixed bottom-0 right-0 p-3"
    style="z-index: 5; right: 0; bottom: 0"
  >
    <div
      id="liveToast"
      class="toast hide"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      data-delay="10000"
    >
      <div
        class="toast-header title"
        :class="
          alert == 'error' ? 'error' : alert == 'success' ? 'success' : ''
        "
      >
        <!-- <img src="..." class="rounded mr-2" alt="..." /> -->
        <strong class="mr-auto">{{ title }}</strong>
        <small>Now</small>
        <button
          type="button"
          class="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="toast-body">{{ subtitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props: ["title", "subtitle", "alert"],
};
</script>

<style scoped>
.title {
  color: white;
}
.error {
  background-color: #ff4d4f;
}
.success {
  background-color: #20c997;
}
</style>