<template>
  <div
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="card m-0 p-0">
      <div class="row m-0 p-0 w-100">
        <!-- ----------------------- -->
        <div class="col-12 col-md-12 col-lg-4 m-0 p-0">
          <LeftHeader
            tital="PROJECT"
            subtital="nonononoono nooononononon"
            line="1"
          />
        </div>
        <!-- ----------------------- -->
        <div class="col-12 col-md-12 col-lg-8 m-0 p-4">
          <RightHeaderSearch />
        </div>
        <!-- ----------------------- -->
        <div class="col-12 m-0 p-0">
          <div class="row m-0 p-0">
            <div class="col-12 m-0 p-0 d-flex justify-content-end">
              <div class="col-12 col-md-6 col-lg-3" @click="goto()">
                <Button1 tital="Add Template" />
              </div>
              <div
                class="col-12 col-md-6 col-lg-3"
                data-toggle="modal"
                data-target="#ModalAddProject"
                @click="newProject()"
              >
                <Button1 tital="Add Project" />
              </div>

              <!-- ---------------------------- -->
              <ModalAddProject
                :key="reloadmodal"
                @GetJobByUser="GetJobByUser"
                @calllength="calllength"
              />
              <!-- ---------------------------- -->
            </div>
          </div>
        </div>
        <!-- ----------------------- -->
        <!-- <div class="col-12 mx-0 my-4 px-lg-5">
          <div class="col-12 col-md-6 col-lg-6 col-xl-3 mx-0 p-0 card bgcard">
            <div class="row mx-0 mt-5 p-0">
              <div class="col-7 m-0 p-0">
                <LeftHeaderCard tital="ID:0113" subtital="ทำสัญญาคุณอัญ" />
              </div>
              
              <div class="col mr-4">
                <div class="col-12 my-2 d-flex justify-content-end">
                  <WaitActive class="" text="Wait" disable="N" />
                </div>
                <div class="col-12 d-flex justify-content-end">
                 
                  <img class="mr-3" src="/images/Vector.png" alt="" />
                </div>
              </div>
            </div>
            
            <div class="row ml-5 mt-3 p-0">
              <div class="col-12 ml-1 p-0">
                <p class="text">ล่าสุด 22 - 09 2564 เวลา 15.00</p>
              </div>
            </div>
          
            <div class="row ml-5 mt-3 p-0 text1">
              <div class="col-12 ml-1 p-0">
                <p class="m-0 p-0">Sales Contract</p>
                <p class="m-0 p-0">Transfer Approved</p>
              </div>
            </div>
            
            <div class="row m-0 p-3">
              <div class="col-12 m-0 p-0">
                <Detail
                  tital="เซ็นสัญญา แจ้งยอดชำระ"
                  subtital="Sophia"
                  date="ล่าสุด 22 - 09 2564 เวลา 15.00"
                  WA="Active"
                  inporcress="N"
                  disable="N"
                />
              </div>
              <div class="col-12 m-0 p-0">
                <Detail
                  tital="แจ้งเตือนลูกค้าครั้งที่ 1"
                  subtital="Sophia"
                  date="ล่าสุด 22 - 09 2564 เวลา 15.00"
                  WA="Active"
                  inporcress="N"
                  disable="N"
                />
              </div>
              <div class="col-12 m-0 p-0">
                <Detail
                  tital="ตรวจสอบยอดชำระ"
                  subtital="Sophia"
                  date="ล่าสุด 22 - 09 2564 เวลา 15.00"
                  WA="Active"
                  inporcress="N"
                  disable="N"
                />
              </div>
            </div>
            
            <div class="row m-0 p-0 d-flex justify-content-center">
              <div class="col-10 border1"></div>
            </div>

           
            <div class="row ml-5 mt-3 p-0 text1">
              <div class="col-12 ml-1 p-0">
                <p class="m-0 p-0">Collect Documents</p>
             
              </div>
            </div>
            
            <div class="row m-0 p-3">
              <div class="col-12 m-0 p-0">
                <Detail
                  tital="เซ็นสัญญา แจ้งยอดชำระ"
                  subtital="Sophia"
                  date="ล่าสุด 22 - 09 2564 เวลา 15.00"
                  WA="Wait"
                  inporcress="Y"
                  disable="N"
                />
              </div>
              <div class="col-12 m-0 p-0">
                <Detail
                  tital="เซ็นสัญญา แจ้งยอดชำระ"
                  subtital="Sophia"
                  date="ล่าสุด 22 - 09 2564 เวลา 15.00"
                  WA="Wait"
                  inporcress="N"
                  disable="Y"
                />
              </div>
            </div>
           
            <div class="row m-0 p-0 d-flex justify-content-center">
              <div class="col-10 border2"></div>
            </div>
            

            <div class="row ml-5 mr-0 mt-3 p-0 text2">
              <div class="col-12 ml-1 p-0">
                <p class="m-0 p-0 font-Roboto">ผู้ดูแล</p>
                
              </div>
              <div class="row m-0 p-0">
                <div class="col m-0 p-0">
                  <img
                    class="
                      ap-img__main
                      bg-opacity-primary
                      wh-32
                      rounded-circle
                      my-3
                      mx-1
                    "
                    src="images/boo.png"
                    alt="profile"
                  />
                </div>
                <div class="col m-0 p-0">
                  <img
                    class="
                      ap-img__main
                      bg-opacity-primary
                      wh-32
                      rounded-circle
                      my-3
                      mx-1
                    "
                    src="images/pond.png"
                    alt="profile"
                  />
                </div>
              </div>
            </div>

           
            <div class="col-12 mx-0 p-0 my-3 d-flex justify-content-center">
              <Button text="DETAIL" />
            </div>
            
          </div>
        </div> -->

        <!-- ----------------------- -->
      </div>
      <div class="col-12 m-0 p-3">
        <div class="row m-0 p-0">
          <div class="col-6 m-0 p-0">
            <i
              @click="previous()"
              v-if="ofs !== 0"
              type="button"
              class="fas fa-angle-left btn bgbtn p-3"
            ></i>
          </div>
          <div class="col-6 m-0 p-0 d-flex justify-content-end">
            <i
              @click="next()"
              v-if="page > 1 && ofs != page - 1"
              type="button"
              class="fas fa-angle-right btn bgbtn p-3"
            ></i>
          </div>
        </div>
      </div>
      <div class="row m-0 p-4 w-100">
        <div
          class="col-12 col-md-6 col-lg-6 col-xl-3 mx-0 p-0"
          v-for="(e, index) in data"
          :key="index"
        >
          <CardDetail :key="e" :projectdata="e" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftHeader from "@/components/Common/LeftHeader.vue";
import RightHeaderSearch from "@/components/Common/RightHeaderSearch.vue";
import Button1 from "@/components/Common/Button1.vue";
import LeftHeaderCard from "@/components/Common/LeftHeaderCard.vue";
import WaitActive from "@/components/Common/WaitActive.vue";
import Detail from "@/components/Common/Detail.vue";
import Button from "@/components/Project/Button.vue";
import ModalAddProject from "@/components/Project/ModalAddProject.vue";
import CardDetail from "@/components/Project/CardDetail.vue";
export default {
  name: "ProjectPage",
  components: {
    LeftHeader,
    RightHeaderSearch,
    Button1,
    LeftHeaderCard,
    WaitActive,
    Detail,
    Button,
    ModalAddProject,
    CardDetail,
  },
  data() {
    return {
      reloadmodal: '0000',
      ofs: 0,
      lmt: 4,
      page: 0,
      data: [
        // { id: "0123", projectname: " ทำสัญญาคุณอัญ" },
        // { id: "0124", projectname: " ทำสัญญาคุณอัญ1" },
        // { id: "0125", projectname: " ทำสัญญาคุณอัญ2" },
        // { id: "0126", projectname: " ทำสัญญาคุณอัญ4" },
      ],
    };
  },
  mounted() {
    this.GetJobByUser();
    this.calllength();
  },
  methods: {
    async next() {
      this.ofs = this.ofs + 1;
      this.GetJobByUser();
    },
    async previous() {
      this.ofs = this.ofs - 1;
      this.GetJobByUser();
    },
    async calllength() {
      try {
        // let ofs =Number(this.ofs)*Number(this.lmt)
        // console.log(ofs);
        let data = { mode: 0, ofs: this.ofs * this.lmt, lmt: this.lmt };
        let getAPI = await this.$API.call_API(
          "post",
          "workflow/GetJobByUser",
          data,
          "auth"
        );
        //console.log(getAPI.data.data[0].countall);
        this.page = getAPI.data.data[0].countall;
        // console.log(Math.ceil(0 / 4));
        this.page = Math.ceil(this.page / 4);
        // console.log(this.page);
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    async GetJobByUser() {
      try {
        //this.data=[]
        let data = { mode: 1, ofs: this.ofs * this.lmt, lmt: this.lmt };
        let getAPI = await this.$API.call_API(
          "post",
          "workflow/GetJobByUser",
          data,
          "auth"
        );
        // console.log(getAPI.data.data);
        this.data = getAPI.data.data;
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    callmodal() {
      this.reloadmodal = Date.now();
    },
    goto() {
      this.$router.push({ name: "WorkFlowPage" });
    },
    newProject(){
      try {
        if(!this.canCreateProject){
          throw "ไม่สามารถเข้าถึงสิทธิ์การสร้าง Project ได้";
        } else {
          this.reloadmodal = Date.now()
        }
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    }
  },
  computed: {
    user: function () {
      return this.$store.getters["auth/getUser"];
    },
    canCreateProject: function () {
      return this.user.is_admin || this.user.permissions.project_create;
    }
  }
};
</script>

<style scoped>
.bgcard {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
}
.text {
  font-size: 0.75em;
  color: #000000;
  font-weight: 400;
}
.text1 {
  font-size: 0.875em;
  color: #000000;
  font-weight: 700;
}
.text2 {
  font-size: 0.75em;
  color: #000000;
  font-weight: 700;
}
.border1 {
  border-bottom: 1px solid #c4c4c4;
}
.border2 {
  border-bottom: 1px solid #0f172a;
}
.bgbtn {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}
</style>