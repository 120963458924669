<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="ModalAddProject"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body m-0 p-0">
          <div class="row m-0 p-0">
            <!-- -------------- head ------------------ -->
            <div class="col-12 mt-4">
              <p class="mb-3">
                <span
                  ><img class="img" src="/images/strategy.png" alt="" /></span
                ><span class="titalmodal p-3">Add Project</span>
              </p>
              <p class="mb-5 subtitalmodal">เพิ่มโปรเจคใหม่ของคุณ</p>
            </div>
            <!-- ------------------------ body ---------------------- -->
            <div class="col-12">
              <form
                v-if="!submitted"
                class="needs-validation"
                v-on:submit.prevent="Confirm"
                novalidate
              >
                <div class="form-row row m-0 p-0">
                  <div class="col-12">
                    <div class="row mx-0 mb-3 p-0">
                      <!-- ----------------------- -->
                      <!-- {{ type }} -->
                      <div class="col-3 m-0 p-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="C"
                            v-model="type"
                            checked
                          />
                          <label
                            class="form-check-label label"
                            for="exampleRadios1"
                          >
                            บริษัท
                          </label>
                        </div>
                      </div>
                      <!-- ----------------------- -->
                      <div class="col-3 m-0 p-0">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value="P"
                            v-model="type"
                          />
                          <label
                            class="form-check-label label"
                            for="exampleRadios2"
                          >
                            บุคคล
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ------------- Company -------------- -->
                  <div class="row m-0 p-2 datacustomer">
                    <div class="col-12 my-2 p-0">
                      <p class="subtitalmodal">ข้อมูลลูกค้า</p>
                    </div>

                    <div class="col-12 m-0 p-0" v-if="type === 'C'">
                      <div class="row m-0 p-0">
                        <div class="col-12 col-md-4">
                          <div class="form-group">
                            <label class="label">คำนำหน้า</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="คำนำหน้า"
                              v-model="prefix"
                            />
                            <div class="valid-feedback">Looks good!</div>
                          </div>
                        </div>
                        <div class="col-12 col-md-8">
                          <div class="form-group">
                            <label class="label">ชื่อบริษัท *</label>
                            <input
                              type="text"
                              :class="{
                                'form-control': true,
                                'is-invalid':
                                  company_name === '' &&
                                  type === 'C' &&
                                  checkvalid,
                              }"
                              required
                              placeholder="ชื่อบริษัท"
                              v-model="company_name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- ------------- name -------------- -->

                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">ชื่อลูกค้า *</label>
                        <input
                          type="text"
                          :class="{
                            'form-control': true,
                            'is-invalid': fname === '' && checkvalid,
                          }"
                          required
                          placeholder="ชื่อลูกค้า"
                          v-model="fname"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">นามสกุล *</label>
                        <input
                          type="text"
                          :class="{
                            'form-control': true,
                            'is-invalid': ename === '' && checkvalid,
                          }"
                          required
                          placeholder="นามสกุล"
                          v-model="ename"
                        />
                      </div>
                    </div>
                    <!-- ------------- email mobile1 mobile2 -------------- -->

                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">อีเมล *</label>
                        <input
                          type="email"
                          :class="{
                            'form-control': true,
                            'is-invalid': email_address === '' && checkvalid,
                          }"
                          required
                          placeholder="อีเมล"
                          v-model="email_address"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">โทรศัพท์ *</label>
                        <div class="input-group">
                          <input
                            type="text"
                            :class="{
                              'form-control': true,
                              'is-invalid': mobile === '' && checkvalid,
                            }"
                            required
                            placeholder="โทรศัพท์"
                            v-model="mobile"
                          />
                          <div
                            class="input-group-append"
                            @click="findbyphone()"
                          >
                            <button class="btn find" type="button">
                              ค้นหา
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">โทรศัพท์ 2</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="โทรศัพท์ 2"
                          v-model="mobile2"
                        />
                      </div>
                    </div>
                    <!-- ------------- Project name -------------- -->
                    <div class="ccol-12 col-md-6">
                      <div class="form-group">
                        <label class="label">ซื่อโปรเจค *</label>
                        <input
                          type="text"
                          :class="{
                            'form-control': true,
                            'is-invalid': jobName === '' && checkvalid,
                          }"
                          required
                          placeholder="ซื่อโปรเจค"
                          v-model="jobName"
                        />
                      </div>
                    </div>
                    <!-- ------ Template ------ -->
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">Template *</label>
                        <select
                          id="inputState"
                          :class="{
                            'form-control': true,
                            'is-invalid': templateName === '' && checkvalid,
                          }"
                          required
                          v-model="templateName"
                        >
                          <option value="">เลือก template</option>
                          <option
                            v-for="(e, index) in datatemplate"
                            :key="index"
                            :value="e"
                          >
                            {{ e.job_template_name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- ------------------ ข้อมูลสินค้า ---------------- -->
                    <div class="col-12 border-top border-bottom mx-0 my-2">
                      <div class="row">
                        <!-- ------ ข้อมูลสินค้า ------ -->
                        <div class="col-12 my-2 p-0">
                          <p class="subtitalmodal">ข้อมูลสินค้า</p>
                        </div>

                        <!-- ------------- กำลังไฟฟ้า (kWp) * -------------- -->
                        <div class="ccol-12 col-md-6">
                          <div class="form-group">
                            <label class="label">กำลังไฟฟ้า (kWp) *</label>
                            <input
                              type="number"
                              :class="{
                                'form-control': true,
                                'is-invalid': sola_size === '' && checkvalid,
                              }"
                              placeholder="กำลังไฟฟ้า"
                              v-model="sola_size"
                            />
                          </div>
                        </div>

                        <!-- ------------- ประเภทโซลาเซลล์ -------------- -->
                        <div class="ccol-12 col-md-6">
                          <div class="form-group">
                            <label class="label">ประเภทโซลาเซลล์ *</label>
                            <!-- <input
                              type="text"
                              :class="{
                                'form-control': true,
                              }"
                              placeholder="ประเภทโซลาเซลล์"
                              v-model="sola_type"
                            /> -->
                            <select
                              id="inputState"
                              :class="{
                                'form-control': true,
                              }"
                              v-model="sola_type"
                            >
                              <option value="null">
                                เลือก ประเภทโซลาเซลล์
                              </option>
                              <option
                                v-for="(e, index) in SolarInverters.SolarCells"
                                :key="index"
                                :value="e.id"
                              >
                                {{ e.name }}/{{ e.model }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- -------------จำนวนโซลาเซลล์ -------------- -->
                        <div class="ccol-12 col-md-6">
                          <div class="form-group">
                            <label class="label">จำนวนโซลาเซลล์ *</label>
                            <input
                              type="number"
                              :class="{
                                'form-control': true,
                              }"
                              :disabled="sola_type == null"
                              placeholder="จำนวนโซลาเซลล์"
                              v-model="sola_page"
                            />
                          </div>
                        </div>

                        <!-- ------------- ประเภทอินเวอเตอร์ -------------- -->
                        <div class="ccol-12 col-md-6">
                          <div class="form-group">
                            <label class="label">ประเภทอินเวอเตอร์ *</label>
                            <!-- <input
                              type="text"
                              :class="{
                                'form-control': true,
                              }"
                              placeholder="ประเภทอินเวอเตอร์"
                              v-model="inverter_type"
                            /> -->

                            <select
                              id="inputState"
                              :class="{
                                'form-control': true,
                              }"
                              v-model="inverter_type"
                            >
                              <option value="null">
                                เลือก ประเภทอินเวอเตอร์
                              </option>
                              <option
                                v-for="(e, index) in SolarInverters.Inverters"
                                :key="index"
                                :value="e.id"
                              >
                                {{ e.name }}/{{ e.model }}
                              </option>
                            </select>
                          </div>
                        </div>

                        <!-- ------------- จำนวนอินเวอเตอร์-------------- -->
                        <div class="ccol-12 col-md-6">
                          <div class="form-group">
                            <label class="label">จำนวนอินเวอเตอร์ *</label>
                            <input
                              type="number"
                              :class="{
                                'form-control': true,
                              }"
                              :disabled="inverter_type == null"
                              placeholder="จำนวนอินเวอเตอร์"
                              v-model="inverter_page"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- ---------------------------------------------- -->
                    <!-- ------------------ add user ---------------- -->
                    <div class="col-12">
                      <div class="row">
                        <!-- ------ user ------ -->

                        <div class="col-12 col-md-6">
                          <div class="card p-2 overflow">
                            <label class="label">เพิ่มบุคคล *</label>
                            <div
                              class="row pb-2"
                              v-for="(e, i) in userlist"
                              :key="i"
                            >
                              <div class="col-8">
                                <p>{{ e.name }} {{ e.lname }}</p>
                              </div>
                              <div class="col-4">
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  @click="adduser(e)"
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- ------ add user ------ -->
                        <div class="col-12 col-md-6">
                          <div class="card p-2 overflow">
                            <label class="label">บุคคลที่สามารถเข้าถึง</label>
                            <p
                              class="text-danger"
                              v-if="$store.getters['user/getuser'].length == 0"
                            >
                              กรุณาเพิ่มบุคคลที่สามารถเข้าถึง *
                            </p>
                            <div
                              class="row pb-2"
                              v-for="(e, i) in $store.getters['user/getuser']"
                              :key="i"
                            >
                              <div class="col-8">
                                <p>{{ e.name }}</p>
                              </div>
                              <div class="col-4">
                                <button
                                  type="button"
                                  class="btn btn-danger"
                                  @click="removeuser(e)"
                                >
                                  -
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- ----------------- house_no building------ -->
                  <div class="row m-0 p-2">
                    <div class="col-12 my-2 p-0">
                      <p class="subtitalmodal">ข้อมูลที่อยู่</p>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">บ้านเลขที่</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="บ้านเลขที่"
                          v-model="house_no"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">ตึก/อาคาร</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="ตึก/อาคาร"
                          v-model="building"
                        />
                      </div>
                    </div>
                    <!-- ----------------- moo alley------ -->
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">หมู่ที่</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="หมู่ที่"
                          v-model="moo"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">ตรอก/ซอย</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="ตรอก/ซอย"
                          v-model="alley"
                        />
                      </div>
                    </div>
                    <!-- ----------------- road district------ -->
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">ถนน</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="ถนน"
                          v-model="road"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">อำเภอ</label>
                        <input
                          type="text"
                          :class="{
                            'form-control': true,
                            'is-invalid': district === '' && checkvalid,
                          }"
                          required
                          placeholder="อำเภอ"
                          v-model="district"
                        />
                      </div>
                    </div>
                    <!-- ----------------- subdistrict province postcode------ -->
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">ตำบล</label>
                        <input
                          type="text"
                          :class="{
                            'form-control': true,
                            'is-invalid': subdistrict === '' && checkvalid,
                          }"
                          required
                          placeholder="ตำบล"
                          v-model="subdistrict"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">จังหวัด</label>
                        <input
                          type="text"
                          :class="{
                            'form-control': true,
                            'is-invalid': province === '' && checkvalid,
                          }"
                          required
                          placeholder="จังหวัด"
                          v-model="province"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">รหัสไปรษณีย์</label>
                        <input
                          type="text"
                          :class="{
                            'form-control': true,
                            'is-invalid': postcode === '' && checkvalid,
                          }"
                          required
                          placeholder="รหัสไปรษณีย์"
                          v-model="postcode"
                        />
                      </div>
                    </div>
                    <!-- ----------------- country phone ------ -->
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">ประเทศ</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="ประเทศ"
                          v-model="country"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">โทรศัพท์</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="โทรศัพท์"
                          v-model="phone"
                        />
                      </div>
                    </div>
                    <!-- ----------------- mobile fax ------ -->
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">โทรศัพท์ 2</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="โทรศัพท์ 2"
                          v-model="phone2"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="label">แฟกซ์</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="แฟกซ์"
                          v-model="fax"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ---------------------------- alert ----------------- -->
                <div
                  class="alert-icon-big alert alert-danger"
                  role="alert"
                  v-if="alert"
                >
                  <div class="alert-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-layers"
                    >
                      <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                      <polyline points="2 17 12 22 22 17"></polyline>
                      <polyline points="2 12 12 17 22 12"></polyline>
                    </svg>
                  </div>

                  <div class="alert-content">
                    <h6 class="alert-heading">Error</h6>

                    <p>กรุณาใส่ข้อมูลให้ครบถ้วน</p>
                  </div>
                </div>
                <!-- ------- footer-------------------- -->
                <div class="col-12 m-0 p-3 d-flex justify-content-end footter">
                  <button
                    type="button"
                    class="btn close mx-2 px-5"
                    @click="hidemodal()"
                  >
                    Close
                  </button>
                  <button type="submit" class="btn comfirm mx-2 px-5">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast
      :title="toast.title"
      :subtitle="toast.subtitle"
      :alert="toast.alert"
    />
  </div>
</template>

<script>
import Toast from "@/components/Common/Toast.vue";
export default {
  name: "ModalAddProject",
  components: { Toast },
  data() {
    return {
      toast: { title: "", subtitle: "", alert: "" },
      alert: false,
      submitted: false,
      valid: false,
      checkvalid: false,
      userlist: "",
      type: "C",
      sola_size: null,
      sola_page: null,
      sola_type: null,
      inverter_page: null,
      inverter_type: null,
      customerName: "",
      templateName: "",
      jobName: "",
      prefix: "",
      company_name: "",
      fname: "",
      ename: "",
      email_address: "",
      mobile: "",
      mobile2: "",
      house_no: "",
      building: "",
      moo: "",
      alley: "",
      road: "",
      district: "",
      subdistrict: "",
      province: "",
      postcode: "",
      country: "",
      phone: "",
      phone2: "",
      fax: "",
      datatemplate: [],
      SolarInverters: [],
    };
  },
  watch: {
    inverter_type: function () {
      if (this.inverter_type == "null") {
        this.inverter_type = null;
      }
    },
    sola_type: function () {
      if (this.sola_type == "null") {
        this.sola_type = null;
      }
    },
  },
  created() {
    this.data_Solar_Inverters();
  },
  mounted() {
    this.dropdown();
    this.redata();
    this.GetUsers();
  },
  methods: {
    async data_Solar_Inverters() {
      try {
        let data = {};
        let getAPI = await this.$API.call_API(
          "get",
          "workflow/data_Solar_Inverters",
          data,
          "auth"
        );
        // console.log(getAPI.data);
        this.SolarInverters = getAPI.data;
      } catch (error) {
        console.log(error);
      }
    },
    toastshow() {
      $("#liveToast").toast("show");
    },
    validate() {
      this.checkvalid = true;
      if (
        this.type == "P" &&
        this.fname !== "" &&
        this.ename !== "" &&
        this.email_address !== "" &&
        this.mobile !== "" &&
        this.jobName !== "" &&
        this.templateName !== "" &&
        this.district !== "" &&
        this.subdistrict !== "" &&
        this.province !== "" &&
        this.postcode !== "" &&
        this.sola_size !== null &&
        this.$store.getters["user/getuser"].length > 0
      ) {
        if (this.email_address.indexOf("@") !== -1) {
          this.valid = true;
        }

        // this.submitted = true;
      } else if (
        this.type == "C" &&
        this.company_name !== "" &&
        this.fname !== "" &&
        this.ename !== "" &&
        this.email_address !== "" &&
        this.mobile !== "" &&
        this.jobName !== "" &&
        this.templateName !== "" &&
        this.district !== "" &&
        this.subdistrict !== "" &&
        this.province !== "" &&
        this.postcode !== "" &&
        this.sola_size !== null &&
        this.$store.getters["user/getuser"].length > 0
      ) {
        if (this.email_address.indexOf("@") !== -1) {
          this.valid = true;
        }
      }
    },
    hidemodal() {
      $("#ModalAddProject").modal("hide");
    },
    async findbyphone() {
      try {
        if (this.mobile == "" || this.type == "") {
          throw "from_bank";
        }
        let data = { mobilePhone: this.mobile, custType: this.type };
        let getAPI = await this.$API.call_API(
          "post",
          "workflow/FindCustomer",
          data,
          "auth"
        );

        //console.log(JSON.parse(getAPI.data.data[0].jsnCustInfo));
        let callphone = JSON.parse(getAPI.data.data[0].jsnCustInfo);
        // ---------- set data ---------------------

        this.prefix = callphone.prefix;
        this.company_name = callphone.company_name;
        this.fname = callphone.fname;
        this.ename = callphone.ename;
        this.email_address = callphone.email_address;
        this.mobile = callphone.mobile;
        this.mobile2 = callphone.mobile2;
        this.house_no = callphone.house_no;
        this.building = callphone.building;
        this.moo = callphone.moo;
        this.alley = callphone.alley;
        this.road = callphone.road;
        this.district = callphone.district;
        this.subdistrict = callphone.subdistrict;
        this.province = callphone.province;
        this.postcode = callphone.postcode;
        this.country = callphone.country;
        this.phone = callphone.phone;
        this.phone2 = callphone.phone2;
        this.fax = callphone.fax;

        // ---------- set data ---------------------
      } catch (error) {
        if (error == "from_bank") {
          this.toast = {
            title: "Oop",
            subtitle: "กรุณาใส่ข้อมูลให้ครบถ้วน",
            alert: "error",
          };
        } else {
          this.toast = {
            title: "Oop",
            subtitle: error.message,
            alert: "error",
          };
        }
        this.toastshow();
      }
    },
    async Confirm() {
      try {
        this.validate();
        this.alert = false;
        if (this.valid) {
          let data = {
            JSN_CUSTOMER: JSON.stringify([
              {
                type: this.type,
                prefix: this.prefix,
                company_name: this.company_name,
                fname: this.fname,
                ename: this.ename,
                email_address: this.email_address,
                mobile: this.mobile,
                mobile2: this.mobile2,
                house_no: this.house_no,
                building: this.building,
                moo: this.moo,
                alley: this.alley,
                road: this.road,
                district: this.district,
                subdistrict: this.subdistrict,
                province: this.province,
                postcode: this.postcode,
                country: this.country,
                phone: this.phone,
                phone2: this.phone2,
                fax: this.fax,
              },
            ]),
            TEMPLATE_NAME: this.templateName.job_template_name,
            PROJECT_NAME: this.jobName,
            pwr: this.sola_size,
            solarTypeId: this.sola_type,
            solarCount: this.sola_page,
            inverterId: this.inverter_type,
            inverterCount: this.inverter_page,
          };
          //console.log(data);
          let getAPI = await this.$API.call_API(
            "post",
            "workflow/AddMainJobStages",
            data,
            "auth"
          );

          let mydata = { jobId: getAPI.data.data[0].job_id, user: [] };
          for await (var i of this.$store.getters["user/getuser"]) {
            mydata.user.push(i.id);
          }
          let getAPIuser = await this.$API.call_API(
            "post",
            "workflow/AddMainJobStageWorkers",
            mydata,
            "auth"
          );

          this.$store.dispatch("user/actionc_reuser");
          this.$MAIN.showSuccessAlert(this, "เพิ่มโปรเจค สำเร็จ");

          this.hidemodal();
          this.$emit("GetJobByUser");
          this.$emit("calllength");
        } else {
          //this.alert = true;
          if (this.email_address.indexOf("@") == -1) {
            throw "from_error_email";
          }
          throw "from_error";
        }

        // if (this.type == "C") {
        //   if (
        //     this.company_name == "" ||
        //     this.fname == "" ||
        //     this.ename == "" ||
        //     this.mobile == ""
        //   ) {
        //     throw "กรุณาใส่ข้อมูล ชื่อบริษัท ชื่อ สกุล โทรศัพท์";
        //   }
        // } else if (this.type == "P") {
        //   if (this.fname == "" || this.ename == "" || this.mobile == "") {
        //     throw "กรุณาใส่ข้อมูล ชื่อ สกุล โทรศัพท์";
        //   }
        // }
        // if (this.templateName == "" || this.jobName == "") {
        //   throw "กรุณาใส่ข้อมูลให้ครบถ้วน";
        // }
        //   console.log(data);
      } catch (error) {
        console.log(error);
        if (error == "from_error") {
          this.toast = {
            title: "Oop",
            subtitle: "กรุณาใส่ข้อมูลให้ครบถ้วน",
            alert: "error",
          };
        } else if (error == "from_error_email") {
          this.toast = {
            title: "Oop",
            subtitle: "อีเมลของคุณผิดรูปเเบบ",
            alert: "error",
          };
        } else {
          this.toast = {
            title: "Oop",
            subtitle: error.message,
            alert: "error",
          };
        }
        this.toastshow();
      }
    },
    redata() {
      this.type = "C";
      this.customerName = "";
      this.templateName = "";
      this.jobName = "";
      this.prefix = "";
      this.company_name = "";
      this.fname = "";
      this.ename = "";
      this.email_address = "";
      this.mobile = "";
      this.mobile2 = "";
      this.house_no = "";
      this.building = "";
      this.moo = "";
      this.alley = "";
      this.road = "";
      this.district = "";
      this.subdistrict = "";
      this.province = "";
      this.postcode = "";
      this.country = "";
      this.phone = "";
      this.phone2 = "";
      this.fax = "";
      this.sola_size = null;
      this.sola_page = null;
      this.sola_type = null;
      this.inverter_page = null;
      this.inverter_type = null;
    },
    async dropdown() {
      try {
        // console.log("dropdown");
        let data = {};
        let getAPI = await this.$API.call_API(
          "get",
          "workflow/GetListJobStageTemplates",
          data,
          "auth"
        );
        // console.log("5555");
        // console.log(getAPI);
        // console.log(getAPI.data);
        // console.log(getAPI.data.data);
        this.datatemplate = getAPI.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async GetUsers() {
      try {
        // console.log("dropdown");
        let data = {};
        let getAPI = await this.$API.call_API("get", "user/user", data, "auth");

        this.userlist = getAPI.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    adduser(value) {
      this.$store.dispatch("user/actionc_addusre", value);
    },
    removeuser(value) {
      this.$store.dispatch("user/actionc_removrusre", value);
    },
  },
};
</script>

<style scoped>
.titalmodal {
  color: #0f172a;
  font-weight: 600;
  font-size: 2.25em;
}
.subtitalmodal {
  color: #0f172a;
  font-weight: 600;
  font-size: 1.125em;
}
.close {
  color: #475569;
  font-weight: 600;
  font-size: 0.75em;
  background: #ffffff;
  border: 1px solid #cbd5e1;
  border-radius: 5px;
}
.comfirm {
  color: #f8fafc;
  font-weight: 600;
  font-size: 0.75em;
  background: #20c997;
  border: 1px solid #20c997;
  border-radius: 5px;
}
.label {
  color: #0f172a;
  font-weight: 600;
  font-size: 0.875em;
}
.footter {
  background: #f8fafc;
}
.img {
  width: 48px;
  height: 48px;
}
.datacustomer {
  border-radius: 25px;
  border: 2px dotted #bfc4b8;
}
.find {
  color: #f8fafc;
  background: #20c997;
  border: 1px solid #20c997;
  border-radius: 5px;
}
.overflow {
  height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.overflow ::-webkit-scrollbar {
  display: none;
}
</style>