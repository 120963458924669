<template>
  <div
    :class="inporcress == 'Y' ? 'inporcress' : ''"
    class="row m-0 pl-5 py-3 mt-3"
  >
    <div class="col-12 m-0 pl-3">
      <div class="row m-0 p-0">
        <div class="col-6 col-md-7 col-lg-8 m-0 p-0">
          <p :class="disable == 'Y' ? 'tital2' : 'tital'" class="m-0 p-0">
            {{ tital }}
          </p>
          <p :class="disable == 'Y' ? 'subtital2' : 'subtital'" class="m-0 p-0">
            {{ subtital }}
          </p>
          <p :class="disable == 'Y' ? 'date2' : 'date'" class="m-0 p-0">
            {{ date }}
          </p>
        </div>
        <!-- --------------------------------- -->
        <div class="col-6 col-md col-lg mt-3 mx-0 px-md-4 px-lg-4">
          <!-- {{WA}}{{disable}} -->
          <WaitActive :key="tital" :text="WA" :disable="disable" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaitActive from "@/components/Common/WaitActive.vue";
export default {
  name: "Detail",
  props: ["tital", "subtital", "date", "WA", "inporcress", "disable"],
  components: { WaitActive },
};
</script>

<style scoped>
.inporcress {
  background: #f8f8f8;
  /* filter: blur(4px); */
  border-radius: 19px;
}
.tital {
  font-weight: 600;
  font-size: 0.81em;
  color: #0f172a;
}
.tital2 {
  font-weight: 600;
  font-size: 0.875em;
  color: #cbd5e1;
}
.subtital {
  font-size: 0.75em;
  color: #64748b;
  font-weight: 400;
}
.subtital2 {
  font-size: 0.75em;
  color: #cbd5e1;
  font-weight: 400;
}
.date {
  color: #868686;
  font-weight: 400;
  font-size: 0.75em;
}
.date2 {
  color: #c4c4c4;
  font-weight: 400;
  font-size: 0.75em;
}
</style>