<template>
  <div class="m-0 btn px-5 bg font">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Button",
  props: ["text"],
};
</script>

<style scoped>
.bg {
  background: #64748b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.font {
  font-weight: normal;
  font-size: 1.5em;
  color: #ffffff;
}
</style>