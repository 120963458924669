<template>
  <div class="card bgcard mx-1 h-100">
    <!-- {{projectdata}} -->
    <div class="container m-0 p-0">
      <div class="row m-0 p-0">
        <div class="col-12 m-0 p-0">
          <div class="row mx-0 mt-5 p-0">
            <div class="col-7 m-0 p-0">
              <!-- {{projectdata.job_stage_name.length}} -->
              <LeftHeaderCard
                :tital="`ID:` + projectdata.job_num"
                :subtital="jobname"
                data-toggle="tooltip"
                data-placement="top"
                :title="projectdata.job_name"
              />
            </div>
            <!-- ------------------------- -->
            <div class="col mr-4">
              <div class="col-12 my-2 d-flex justify-content-end">
                <WaitActive
                  :key="projectdata.job_num"
                  class=""
                  :text="projectdata.status"
                  disable="N"
                />
              </div>
              <!-- <div class="col-12 d-flex justify-content-end"> -->
              <!-- <img class="mr-4" src="/images/Vector.png" alt="" /> -->
              <!-- <img class="mr-3" src="/images/Vector.png" alt="" /> -->
              <!-- </div> -->
            </div>
          </div>
        </div>

        <!-- ----------------------- -->
        <div class="col-12 m-0 p-0">
          <div class="row ml-5 mt-3 p-0">
            <div class="col-12 ml-1 p-0">
              <p class="text">{{ projectdata.updated_at }}</p>
            </div>
          </div>
        </div>

        <!-- --------------------- scoll -------------------- -->
        <div class="m-0 p-0 overflow w-100" v-if="gendata.length > 0">
          <!-- ----------------------- -->
          <div class="m-0 p-0" v-for="(e, i) in gendata" :key="i">
            <!-- -------------------- -->
            <div class="col-12 m-0 p-0" v-if="e.name !== ''">
              <div class="row ml-5 mt-3 p-0 text1">
                <div class="col-12 ml-1 p-0">
                  <p class="m-0 p-0">{{ e.name }}</p>
                  <!-- <p class="m-0 p-0">Transfer Approved</p> -->
                </div>
              </div>
            </div>
            <!-- --------------------- -->
            <div class="col-12 m-0 p-0">
              <div class="row m-0 p-3">
                <div
                  class="col-12 m-0 p-0"
                  v-for="(e1, i1) in e.data"
                  :key="i1"
                >
                  <Detail
                    :key="e1"
                    :tital="e1.text"
                    :subtital="e1.created_by"
                    :date="e1.updated_at"
                    :WA="e1.status"
                    inporcress="N"
                    disable="N"
                  />
                </div>
              </div>
            </div>
            <!-- ------------------------ -->
            <div class="col-12 m-0 p-0">
              <div class="row m-0 p-0 d-flex justify-content-center">
                <div class="col-10 border1"></div>
              </div>
            </div>
            <!-- ------------------------- -->
          </div>

          <!-- ----------------------------------------- -->
          <!-- <div class="col-12 m-0 p-0">
            <div class="row ml-5 mt-3 p-0 text1">
              <div class="col-12 ml-1 p-0">
                <p class="m-0 p-0">Collect Documents</p>
               
              </div>
            </div>
          </div> -->

          <!-- ----------------------- -->
          <!-- <div class="col-12 m-0 p-0">
            <div class="row m-0 p-3">
              <div class="col-12 m-0 p-0">
                <Detail
                  tital="เซ็นสัญญา แจ้งยอดชำระ"
                  subtital="Sophia"
                  date="ล่าสุด 22 - 09 2564 เวลา 15.00"
                  WA="Wait"
                  inporcress="Y"
                  disable="N"
                />
              </div>
              <div class="col-12 m-0 p-0">
                <Detail
                  tital="เซ็นสัญญา แจ้งยอดชำระ"
                  subtital="Sophia"
                  date="ล่าสุด 22 - 09 2564 เวลา 15.00"
                  WA="Wait"
                  inporcress="N"
                  disable="Y"
                />
              </div>
            </div>
          </div> -->

          <!-- -------------------------------------- -->
        </div>
        <!-- --------------------- scoll -------------------- -->
        <!-- ----------------------------------------- -->
        <div class="col-12 m-0 p-0">
          <div class="row m-0 p-0 d-flex justify-content-center">
            <div class="col-10 border2"></div>
          </div>
        </div>

        <div class="col-12 m-0 p-0">
          <div class="row ml-5 mr-0 mt-3 p-0 text2">
            <div class="col-12 ml-1 p-0">
              <p class="m-0 p-0 font-Roboto">ผู้ดูแล</p>
              <!-- <p class="m-0 p-0">Transfer Approved</p> -->
            </div>
            <div class="row m-0 p-0">
              <div class="col m-0 p-0">
                <img
                  class="
                    ap-img__main
                    bg-opacity-primary
                    wh-32
                    rounded-circle
                    my-3
                    mx-1
                  "
                  :src="projectdata.user_image"
                  alt="profile"
                />
              </div>
              <!-- <div class="col m-0 p-0">
          <img
            class="
              ap-img__main
              bg-opacity-primary
              wh-32
              rounded-circle
              my-3
              mx-1
            "
            src="images/pond.png"
            alt="profile"
          />
        </div> -->
            </div>
          </div>
        </div>

        <!-- ----------------------- -->
        <div class="col-12 m-0 p-0 align-self-end">
          <div class="row m-0 p-0">
            <div class="col-12 mx-0 p-0 mb-3 d-flex justify-content-center">
              <Button
                class="text-center"
                text="DETAIL"
                @click="goto(projectdata.job_num)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ----------------------- -->
  </div>
</template>

<script>
import LeftHeaderCard from "@/components/Common/LeftHeaderCard.vue";
import WaitActive from "@/components/Common/WaitActive.vue";
import Detail from "@/components/Common/Detail.vue";
import Button from "@/components/Project/Button.vue";
export default {
  name: "CardDetail",
  props: ["projectdata"],
  components: { LeftHeaderCard, WaitActive, Detail, Button },
  data() {
    return {
      jobname: "",
      gendata: [],
    };
  },
  mounted() {
    this.rejobname();
    this.GetStageByJobId();
  },
  methods: {
    async GetStageByJobId() {
      try {
        let data = { jobid: this.projectdata.job_num };
        let getAPI = await this.$API.call_API(
          "post",
          "workflow/GetStageByJobId",
          data,
          "auth"
        );
        //console.log(getAPI.data.data);
        let getdata = getAPI.data.data;
        let key = [];

        let groupdata = [];

        for (var i in getdata) {
          if (getdata[i].group_stage_name == null) {
            groupdata.push({ name: i, data: [] });
          } else {
            let getarray = groupdata.findIndex(
              (e) => e.name === getdata[i].group_stage_name
            );
            if (getarray == -1) {
              groupdata.push({ name: getdata[i].group_stage_name, data: [] });
            }
          }
        }

        for (var i in getdata) {
          if (getdata[i].group_stage_name == null) {
            let getarray = groupdata.findIndex((e) => e.name === i);

            groupdata[getarray].name = "";
            groupdata[getarray].data.push(getdata[i]);
          } else {
            let getarray = groupdata.findIndex(
              (e) => e.name === getdata[i].group_stage_name
            );
            //console.log(getarray);
            groupdata[getarray].data.push(getdata[i]);
          }
        }

        //console.log(groupdata);
        this.gendata = groupdata;
      } catch (error) {
        this.$MAIN.showErrorAlert(this, error);
      }
    },
    rejobname() {
      if (this.projectdata.job_name.length > 17) {
        //console.log(this.projectdata.job_stage_name.substring(0, 16));
        this.jobname = `${this.projectdata.job_name.substring(0, 16)}...`;
      } else {
        this.jobname = this.projectdata.job_name;
      }
    },
    goto(name) {
      let convertid = this.$MAIN.Base64.encode(name);
      // let mydata = { projectdata: this.projectdata, gendata: this.gendata };
      // let senddata = this.$MAIN.Base64.encode(JSON.stringify(mydata));
      //  jobid: this.projectdata.job_num
      // console.log(this.projectdata.user_image);
      // let jobid = this.$MAIN.Base64.encode(this.projectdata.job_num);
      // let img = this.$MAIN.Base64.encode(this.projectdata.user_image);
      let mydata = {
        img: this.projectdata.user_image,
        jobid: this.projectdata.job_num,
      };
      let senddata = this.$MAIN.Base64.encode(JSON.stringify(mydata));
      this.$router.push({
        name: "JobDetailPage",
        params: { id: convertid, mydata: senddata },
      });
    },
  },
};
</script>

<style scoped>
.bgcard {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
}
.text {
  font-size: 0.75em;
  color: #000000;
  font-weight: 400;
}
.text1 {
  font-size: 0.875em;
  color: #000000;
  font-weight: 700;
}
.text2 {
  font-size: 0.75em;
  color: #000000;
  font-weight: 700;
}
.border1 {
  border-bottom: 1px solid #c4c4c4;
}
.border2 {
  border-bottom: 1px solid #0f172a;
}
.overflow {
  height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.overflow ::-webkit-scrollbar {
  display: none;
}
</style>