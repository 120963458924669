<template>
  <div class="row m-0 p-0 bg">
    <div class="col-12 mx-0 my-3 p-0">
      <p class="pl-5 mt-2">
        <span class="tital">{{ tital }}</span
        ><span class="subtital">&nbsp;&nbsp;{{ subtital }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftHeaderCard",
  props: ["tital", "subtital"],
};
</script>

<style scoped>
.bg {
  background: #394352;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 12px 12px 0px;
}
.tital {
  font-size: 1.25em;
  color: #f8fafc;
  font-weight: 700;
}
.subtital {
  font-size: 0.81em;
  color: #f8fafc;
  font-weight: 700;
}
</style>